export default () => {
  const { doFetch } = useFetchApi()
  const { messageInput, messageSuccess } = useSwal()
  const { t } = useNuxtApp().$i18n

  const addReport = async (body) => {
    return await doFetch({
      type: 'report',
      method: 'POST',
      body,
    })
  }

  const doReport = async (target = '', type = '') => {
    if (!target || !type) return false

    const { value } = await messageInput(t('report.title'), `${t('report.target')}: "${target} / ${type}" ${t('report.reportInfo')}`, {
      inputValidator: (value) => {
        if (!value) {
          return t('input.required')
        }
      },
    })
    if (value) {
      const res = await addReport({ target, type, content: value })
      if (res.id) {
        messageSuccess(t('ajax.success'), t('report.success'))
      }
    }
  }
  return {
    doReport,
  }
}
