export default () => {
  const { doFetch } = useFetchApi()

  const getComments = async (target = '', code = '') => {
    if (!target || !code) return false

    return await doFetch({
      type: 'comment',
      input: target,
      params: { code },
      options: { silent: true },
    })
  }

  const deleteComent = async (id) => {
    if (!id) return false

    return await doFetch({
      type: 'commentDelete',
      method: 'DELETE',
      params: { id },
    })
  }

  const addComment = async (target = '', code = '', message = '') => {
    if (!target || !code || !message) return false

    const body = { code, message }

    return await doFetch({
      type: 'commentAdd',
      method: 'POST',
      input: target,
      body,
    })
  }

  const updateComment = async (id, message = '') => {
    if (!id || !message) return false

    const body = { message, id }

    return await doFetch({
      type: 'commentUpdate',
      method: 'PUT',
      body,
    })
  }

  const doLike = async (id, type) => {
    if (!id || !type) return false

    const body = { code: id, type }

    return await doFetch({
      type: 'commentLike',
      method: 'POST',
      body,
    })
  }

  return {
    getComments,
    deleteComent,
    addComment,
    updateComment,
    doLike,
  }
}
