<template>
  <div
    class="z-20 absolute max-h-[50vh] md:max-h-[20rem] overflow-y-auto flex flex-col rounded-md bg-zinc-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
    :class="{
      'w-[80vw] md:w-[20rem]': props.isSearch,
      'max-w-[80vw] md:max-w-[20rem] w-max': !props.isSearch
    }"
    :style="{ 'margin-top': parentSize + 'px', ...props.position }"
  >
    <section v-if="props.isSearch" class="sticky top-0 bg-zinc-800">
      <div class="ring-2 ring-white ring-opacity-20 m-2 px-2 py-1 rounded-lg flex items-center">
        <UIInput type="text" placeholder="Keyword" v-model="keyword" />
        <XMarkIcon class="size-5 cursor-pointer" @click="keyword = ''" />
      </div>
    </section>

    <a
      class="text-sm cursor-pointer flex items-center gap-2 py-2 px-4"
      v-for="item in findMenus"
      :key="item.name"
      :class="itemClass(item)"
      @click="emits('select', item)"
    >
      <component :is="item.icon" class="size-5 flex-none" />
      <span>{{ item.name }}</span>
    </a>
    <div v-if="findMenus.length === 0" class="text-sm font-normal text-zinc-500 flex items-center gap-2 py-2 px-4">
      <MinusCircleIcon class="size-5 flex-none" />
      <span> {{ $t('empty') }} </span>
    </div>
    <!-- <a class="text-red-700 hover:text-red-500 text-sm cursor-pointer flex items-center gap-2">
        <TrashIcon class="size-5" />
        <span>刪除牌組</span>
      </a> -->
  </div>
</template>

<script setup>
import { XMarkIcon, MinusCircleIcon } from '@heroicons/vue/24/outline'

const emits = defineEmits(['select'])

const props = defineProps({
  position: {
    type: Object,
    default: {
      left: 0,
      top: 0
    }
  },
  menus: {
    type: Array,
    default: []
  },
  parentSize: {
    type: Number,
    default: 0
  },
  isSearch: {
    type: Boolean,
    default: false
  },
  findFn: {
    type: Function,
    default: (item, k) => item.name.toLocaleLowerCase().includes(k.toLocaleLowerCase())
  }
})

const keyword = ref('')

const findMenus = computed(() => {
  if (!keyword.value || !props.isSearch) return props.menus
  return props.menus.filter(item => props.findFn(item, keyword.value))
})

const itemClass = item => {
  return item.class || 'text-zinc-300 hover:text-white'
}
</script>
