<template>
  <section class="relative flex flex-col gap-2">

    <div class="flex items-start gap-2" v-if="user.role">
      <UIUser :user="user" class="flex-none" :show="{ head: true, role: true }" />
      <div class="w-full flex items-center gap-2 p-2 rounded-xl default-transition 
        bg-zinc-900/50 text-white hover:bg-zinc-900 focus-within:bg-zinc-900 active:bg-zinc-900">
        <ChatBubbleOvalLeftEllipsisIcon class="flex-none size-7 default-transition text-zinc-300" />
        <UIInput type="text" :placeholder="$t('comment.placeholder')" v-model="message"
          :disabled="props.target === '' || props.code === ''" />
        <UIButton class="btn-sm" @click="doComment('add', [props.target, props.code, message])">
          <PaperAirplaneIcon />
        </UIButton>
      </div>
    </div>

    <div class="flex items-start gap-2" v-else>
      <NuxtLink to="/login"
        class="flex items-center gap-2 px-4 py-2 rounded-xl bg-gradient-to-r from-cyan-500 to-blue-500 w-full hover:shadow-lg hover:shadow-cyan-500/20">
        <span class="text-zinc-300 text-lg">{{ $t('comment.login') }}</span>
        <ArrowRightIcon class="ml-auto size-5" />
      </NuxtLink>
    </div>

    <h3 class="text-right font-bold text-zinc-300 mr-2">{{ comments.length }}{{ $t('comment.unit') }}</h3>

    <section class="relative md:min-h-[8rem]">
      <TransitionOpacity>
        <UILoading v-if="loading" class="absolute inset-0 -m-2 z-10 rounded-xl" block />
      </TransitionOpacity>

      <div class="flex flex-col gap-2">
        <template v-for="(comment, commentid) in comments" :key="`comment-${commentid}`">


          <div class="flex items-start gap-2"
            v-if="blocks.find(e => e.type === 'commentUser' && e.target.name === comment.author.name)">
            <!-- author -->
            <UIUser class="flex-none" :show="{ head: true, role: true }" />
            <div class="w-full flex flex-col gap-2 bg-zinc-900/50 p-2 rounded-xl text-zinc-500 italic">
              {{ $t('block.blocked') }}
            </div>
          </div>

          <template v-else>
            <div class="flex items-start gap-2" v-if="readmore ? true : commentid < 2">
              <!-- author -->
              <UIUser class="flex-none" :user="comment.author" :show="{ head: true, role: true }" />
              <div class="w-full flex flex-col gap-2 bg-zinc-900/50 p-2 rounded-xl">

                <!-- info -->
                <div class="flex items-start gap-2">
                  <div>
                    <h4 class="text-sm font-bold text-[#32c9ff]"> {{ comment.author.name }}</h4>
                    <div class="text-zinc-400 text-xs">
                      {{ $t('comment.publish') }}
                      <span class="font-mono">
                        {{ formatTime(comment.updatedAt, 'YYYY-MM-DD HH:mm') }}
                      </span>
                    </div>
                  </div>

                  <div class="ml-auto relative">
                    <button class="text-white/50" @click="comment.showMenu = true">
                      <EllipsisVerticalIcon class="size-5" />
                    </button>
                    <TransitionCollapse>
                      <UIDropDown :data-id="comment.id" class="!z-10" v-on-click-outside="() => comment.showMenu = false"
                        :position="{ right: 0, top: 0 }" :menus="showMenus(comment)" v-if="comment.showMenu"
                        :parentSize="24" @select="e => {
                          e.action ? e.action(comment) : null;
                          comment.showMenu = false;
                        }" />
                    </TransitionCollapse>
                  </div>
                </div>

                <!-- message -->
                <p v-if="editId !== comment.id" class="whitespace-pre-line text-sm md:txet-base/6 text-zinc-100">{{
                  comment.message
                }}</p>
                <div v-else class="bg-black/30 p-1 rounded-xl">
                  <UIInput type="textarea" v-model="editMessage" rows="3" />
                </div>

                <!-- like -->
                <div class="flex items-center justify-end gap-1">
                  <button v-if="editId === comment.id"
                    class="flex-none rounded-full py-1 pl-1 pr-2 flex items-center bg-white text-zinc-800"
                    @click="doComment('update', [editId, editMessage])">
                    <ChevronRightIcon class="size-4" />
                    <span class="text-xs text-mono leading-none font-bold">{{ $t('comment.submit') }}</span>
                  </button>
                  <button v-if="editId === comment.id"
                    class="flex-none rounded-full py-1 pl-1 pr-2 flex items-center bg-amber-600 text-white"
                    @click="resetEdit">
                    <XMarkIcon class="size-4" />
                    <span class="text-xs text-mono leading-none font-bold">{{ $t('comment.cancel') }}</span>
                  </button>
                  <button class="ml-auto flex-none rounded-full p-1 flex items-center" :class="{
                    'text-zinc-100': comment.likes.vote !== 'LIKE',
                    'text-white bg-red-500': comment.likes.vote === 'LIKE',
                  }" @click="doComment('like', [comment.id, 'LIKE'])">
                    <SolidHeatIcon v-if="comment.likes.vote === 'LIKE'" class="size-4" />
                    <HeartIcon v-else class="size-5" />
                    <span class="text-xs text-mono leading-none font-bold">{{ comment.likes.like }}</span>
                  </button>

                  <button class="flex-none rounded-full p-1 flex items-center" :class="{
                    'text-zinc-100': comment.likes.vote !== 'HATE',
                    'text-white bg-zinc-500': comment.likes.vote === 'HATE',
                  }" @click="doComment('like', [comment.id, 'HATE'])">
                    <SolidHandThumbDownIcon v-if="comment.likes.vote === 'HATE'" class="size-4" />
                    <HandThumbDownIcon v-else class="size-5" />
                    <!-- <span class="font-bold">{{ comment.likes.hate }}</span> -->
                  </button>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>

      <button v-if="!readmore && comments.length > 2" class="mx-auto mt-4 px-4 py-2 w-fit 
      flex items-center justify-center gap-2 
      bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl" @click="readmore = true">
        <BookOpenIcon class="size-6" />
        <span> {{ $t('more') }} </span>
      </button>
    </section>


  </section>
</template>


<script setup>
import {
  BookOpenIcon,
  EllipsisVerticalIcon,
  PaperAirplaneIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  HeartIcon,
  HandThumbDownIcon,
  PencilSquareIcon,
  ChevronRightIcon,
  TrashIcon,
  XMarkIcon,
  NoSymbolIcon,
  ShieldExclamationIcon,
  UserMinusIcon,
  ArrowRightIcon
} from '@heroicons/vue/24/outline'
import { HeartIcon as SolidHeatIcon, HandThumbDownIcon as SolidHandThumbDownIcon } from '@heroicons/vue/24/solid'
import { vOnClickOutside } from '@vueuse/components'
import * as _ from 'lodash'

const props = defineProps({
  target: {
    type: String,
    default: '',
  },
  code: {
    type: String,
    default: '',
  },
})

const globalStore = useGlobalStore()
const { user, blocks } = storeToRefs(globalStore)
const { getBlocks } = globalStore

const { getComments, deleteComent, updateComment, addComment, doLike } = useComment()
const { doReport } = useReport()
const { doBlock } = useBlock()

const readmore = ref(false)
const comments = ref([])
const loading = ref(false)
const message = ref('')
const editMessage = ref('')
const editId = ref('')

const { formatTime } = useTool()
const { messageConfirm } = useSwal()
const { t } = useNuxtApp().$i18n


const showMenus = (comment) => ([
  {
    name: t('comment.edit'), icon: PencilSquareIcon, isOwner: true, action: (comment) => {
      editId.value = comment.id
      editMessage.value = comment.message
    }
  },
  {
    name: t('comment.delete'), icon: TrashIcon, isOwner: true, action: async (comment) => {
      await doComment('delete', [comment.id])
    }, class: 'text-red-700 hover:text-red-500'
  },
  {
    name: t('comment.report'), icon: NoSymbolIcon, isOwner: false, action: async (comment) => {
      await doReport('comment', comment.id)
    }, class: 'text-red-700 hover:text-red-500'
  },
  {
    name: t('user.report'), icon: ShieldExclamationIcon, isOwner: false, action: async (comment) => {
      await doReport('commentUser', comment.author.name)
    }, class: 'text-red-700 hover:text-red-500'
  },
  {
    name: t('user.block'), icon: UserMinusIcon, isOwner: false, action: async (comment) => {
      await blockFlow(comment)
    }, class: 'text-red-700 hover:text-red-500'
  },
].filter(e => comment.isOwner ? e.isOwner : !e.isOwner))

// block flow

const blockFlow = async (comment) => {
  const res = await doBlock('commentUser', comment.id, comment.author.name)
  if (res) {
    await getBlocks()
    await doGetComments()
  }
}


const resetEdit = () => {
  editId.value = ''
  editMessage.value = ''
}

const reset = () => {
  message.value = ''
  editId.value = ''
  editMessage.value = ''
}

// AJAX 
const doGetComments = async () => {
  loading.value = true
  const data = await getComments(props.target, props.code)
  comments.value = _.orderBy(data, 'updatedAt', 'desc').map(e => {
    e.showMenu = false
    return e
  })
  loading.value = false
}

const doComment = async (type, inputs = []) => {
  loading.value = true
  let res = false

  switch (type) {
    case 'delete':
      const { value } = await messageConfirm(t('comment.deleteComment'), t('comment.deleteCommentInfo'))
      if (value) {
        res = await deleteComent(...inputs)
      }
      break
    case 'update':
      res = await updateComment(...inputs)
      break
    case 'add':
      res = await addComment(...inputs)
      break
    case 'like':
      res = await doLike(...inputs)
      break
  }

  if (res) {
    reset()
    await doGetComments()
  }
  else {
    loading.value = false
  }
}


watch(props, async () => {
  await doGetComments()
})


onMounted(async () => {
  await doGetComments()
})

</script>